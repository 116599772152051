.card-input-root {
    display: flex;
    flex-direction: column;
    height: 78px;
}

.card-input {
    display: flex;
    outline: unset;
    width: 100%;
    border: none;
    border-bottom-style: solid;
    border-bottom-color: rgba(0, 0, 0, 0.50);
    height: 54px;
    color: rgba(0, 0, 0, 0.50);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
}

.card-input-error {
    display: flex;
    outline: unset;
    width: 100%;
    border: none;
    border-bottom-style: solid;
    border-bottom-color: #FB4236;
    height: 54px;
    color: rgba(0, 0, 0, 0.50);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
}

.card-error {
    height: 24px;
    margin-top: 10px;
    text-align: start;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
