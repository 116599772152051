$breakpoint-xs: 320px;
$breakpoint-s: 375px;
$breakpoint-m: 460px;

@mixin xs {
  @include breakpoint($breakpoint-xs) {
    @content;
  }
}

@mixin s {
  @include breakpoint($breakpoint-s) {
    @content;
  }
}

@mixin m {
  @include breakpoint($breakpoint-m) {
    @content;
  }
}

@mixin breakpoint($width) {
  @media screen and (min-width: $width) {
    @content;
  }
}

@mixin breakpoint-to($width) {
  @media screen and (max-width: ($width - 1)) {
    @content;
  }
}
