@import "../../src/ui-library/responsive";

.root {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 100vh;
  border-radius: 16px;
  border-top: #8BE0B7;
  overflow-y: auto;
}

.header-title {
  text-align: left;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  margin: 56px 0 32px 24px;

  @include xs {
    margin-right: 24px;
  }

  @include s {
    margin-right: 125px;
  }

  @include m {
    margin-right: 125px;
  }
}
