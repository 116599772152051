.date-input-root {
    display: flex;
}

.date-input {
    display: flex;
    outline: unset;
    width: 100%;
    border: none;
    border-bottom-style: solid;
    border-bottom-color: rgba(0, 0, 0, 0.50);
    height: 54px;
    color: rgba(0, 0, 0, 0.50);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
}

.date-input-error {
    display: flex;
    outline: unset;
    width: 100%;
    border: none;
    border-bottom-style: solid;
    border-bottom-color: #FB4236;
    height: 54px;
    color: rgba(0, 0, 0, 0.50);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
}
