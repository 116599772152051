.top-up-container {
  position: absolute;
  display: flex;
  bottom: 8px;
  left: 0;
  right: 0;
  margin-left: 24px;
  margin-right: 24px;
}

.top-up-button {
  background-color: #ECECEC;
  height: 64px;
  border-radius: 60px;
  border: none;
  width: 100%;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}

.top-up-button-light {
  background-color: #8BE0B7;
  height: 64px;
  border-radius: 60px;
  border: none;
  width: 100%;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}
