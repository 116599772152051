.code-input-root {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.code-input {
  display: flex;
  outline: unset;
  width: 100px;
  border: none;
  border-bottom-style: solid;
  border-bottom-color: rgba(0, 0, 0, 0.50);
  height: 54px;
  color: rgba(0, 0, 0, 0.50);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}

.code-input-error {
  display: flex;
  outline: unset;
  width: 100px;
  border: none;
  border-bottom-style: solid;
  border-bottom-color: #FB4236;
  height: 54px;
  color: rgba(0, 0, 0, 0.50);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}

.code-label {
  color: rgba(0, 0, 0, 0.50);
  text-align: left;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
