.card-container-form {
  width: 100%;
  padding: 16px;
}

.container {
  display: flex;
  justify-content: space-between;
  gap: 16px;
}

.date-container {
  width: 100%;
}

.card-container {
  display: flex;
  position: relative;
}

.right-container {
  position: absolute;
  left: 24px;
  display: flex;
  right: 37px;
  height: 188px;
  border: 1px solid var(--text-black-30, rgba(0, 0, 0, 0.30));;
  border-radius: 16px;
  background-color: white;
  z-index: 2;
}

.left-container {
  position: absolute;
  left: 40px;
  top: 32px;
  display: flex;
  width: calc(100% - 64px);
  right: 64px;
  height: 188px;
  border: 1px solid var(--text-black-30, rgba(0, 0, 0, 0.30));
  border-radius: 16px;
  background-color: white;
  z-index: 1;
}
